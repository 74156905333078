import React, { useContext } from 'react'
import { jwtDecode } from 'jwt-decode'
import pako from 'pako'

const AuthContext = React.createContext()

// https://medium.freecodecamp.org/how-to-protect-your-routes-with-react-context-717670c4713a
class AuthProvider extends React.Component {
  static TOKEN_REFRESH_WINDOW = 30 * 60 * 1000 // 30 mins

  state = {
    user: undefined,
  }

  constructor() {
    super()
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.create = this.create.bind(this)
    this.fetch = this.fetch.bind(this)
    this.tryToken = this.tryToken.bind(this)
    this._checkStatus = this._checkStatus.bind(this)
  }

  componentWillMount() {
    this.deriveState()
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    return this.isTokenValid(this.getToken())
  }

  isTokenValid(token) {
    if (token) {
      try {
        const decoded = jwtDecode(token)
        if (decoded.exp < Date.now() / 1000) {
          return false
        } else {
          return true
        }
      } catch (err) {
        console.error(err)
        return false
      }
    }
    return false
  }

  setToken(idToken) {
    if (this.isTokenValid(idToken)) {
      localStorage.setItem('token', idToken)
    }
  }

  getToken() {
    return localStorage.getItem('token')
  }

  logout() {
    localStorage.removeItem('token')
    this.setState({ user: undefined })
  }

  getProfile() {
    return jwtDecode(this.getToken())
  }

  async fetch(path, options, softLogout = false) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    if (
      options.method === 'POST' &&
      options.body &&
      options.body.length > 1024
    ) {
      headers['Content-Encoding'] = 'deflate'
      options.body = pako.deflate(options.body)
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.getToken()

      if (this.willTokenExpireSoon()) {
        await this.refreshToken()
      }
    }

    return fetch(process.env.REACT_APP_API_HOST + path, {
      headers,
      ...options,
    }).then((res) => this._checkStatus(res, softLogout))
  }

  _checkStatus(response, softLogout = false) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      if (response.status === 204) return {}
      return response.json()
    } else {
      if (response.status === 401 && !softLogout) {
        this.logout()
      }
      var error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }

  async refreshToken() {
    return fetch(process.env.REACT_APP_API_HOST + '/auth/refresh', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(({ token }) => this.tryToken(token))
  }

  willTokenExpireSoon() {
    const soon = new Date(
      new Date().getTime() + AuthProvider.TOKEN_REFRESH_WINDOW
    )
    const decoded = jwtDecode(this.getToken())
    return decoded.exp < soon.getTime() / 1000
  }

  recover(email) {
    return this.fetch('/users/recover', {
      method: 'POST',
      body: JSON.stringify({ email }),
    })
  }

  updatePassword(token, password) {
    return this.fetch('/users/resetpassword', {
      method: 'POST',
      body: JSON.stringify({
        token,
        password,
      }),
    })
  }

  login(email, password) {
    // Get a token from api server using the fetch api
    return this.fetch('/auth/local', {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    }).then((res) => {
      this.setToken(res.token)
      return this.deriveState()
    })
  }

  create(email, password, name) {
    return this.fetch('/users', {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        name,
      }),
    }).then((res) => {
      this.setToken(res.token)
      return this.deriveState()
    })
  }

  deriveState() {
    if (this.loggedIn()) {
      let user = this.getProfile()
      this.setState({ user: user })
      return user
    }
  }

  tryToken(token) {
    this.setToken(token)
    return this.deriveState()
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          user: this.state.user,
          recover: this.recover,
          updatePassword: this.updatePassword,
          login: this.login,
          create: this.create,
          logout: this.logout,
          tryToken: this.tryToken,
          fetch: this.fetch,
          isPro: this.state.user && this.state.user.isPro,
          refreshToken: this.refreshToken,
          getToken: this.getToken,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer
const useAuth = () => {
  const { user, login, logout, create, tryToken, refreshToken } =
    useContext(AuthContext)
  return { user, login, logout, create, tryToken, refreshToken }
}
export { AuthProvider, AuthConsumer, AuthContext, useAuth }

import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import SharedChartsTab from '../../molecules/SharedChartsTab'

const ChartsPage = () => {
  const [activeTab, setActiveTab] = useState('1')
  const toggle = (tab) => setActiveTab(tab)

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1')
            }}
          >
            Shared
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2')
            }}
          >
            All (TBD)
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <SharedChartsTab />
        </TabPane>
        <TabPane tabId="2">
          <p>TBD</p>
        </TabPane>
      </TabContent>
    </>
  )
}

export default ChartsPage

import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../molecules/AuthContext'
import ArrayTable from '../../molecules/ArrayTable'
import { Button, TextField } from '@material-ui/core'

export default function UsersPage() {
  const [q, setQ] = useState('{}')
  const [users, setUsers] = useState(undefined)
  const auth = useContext(AuthContext)

  const [pagination, setPagination] = useState()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(15)

  function handleChangePage(newPage) {
    setPage(newPage)
    setUsers(undefined)
  }

  function handleChangePerPage(event) {
    setPerPage(parseInt(event.target.value, 10))
    setPage(1)
    setUsers(undefined)
  }

  function handleChangeSearch(event) {
    setQ(event.target.value)
  }

  function submitSearch(e) {
    e.preventDefault()
    setUsers(undefined)
  }

  useEffect(() => {
    const loadUsers = async () => {
      let resp = await auth.fetch(
        `/users?perPage=${perPage}&page=${page}&q=${encodeURIComponent(q)}`,
        {
          method: 'get',
        }
      )
      console.log(resp)
      setPagination(resp.pagination)
      setUsers(resp.users)
    }
    if (!users) {
      loadUsers()
    }
  }, [users, auth, page, perPage, q])

  return (
    <div>
      <h1>Users</h1>
      {users ? (
        <React.Fragment>
          <form noValidate autoComplete="off" onSubmit={submitSearch}>
            <TextField
              fullWidth
              label="JSON Search"
              value={q}
              onChange={handleChangeSearch}
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
              Search
            </Button>
          </form>
          <ArrayTable
            pagination={pagination}
            items={users}
            {...{ perPage, page, handleChangePage, handleChangePerPage }}
            fields={[
              {
                name: 'Name',
                value: (u, i) => u.name,
              },
              {
                name: 'Email',
                value: (u, i) => u.email,
              },
              {
                name: 'Provider',
                value: (u, i) => u.provider,
              },
              {
                name: 'Role',
                value: (u, i) => u.role,
              },
              {
                name: 'Pro',
                value: (u, i) => (u.isPro ? 'yes' : 'no'),
              },
              {
                name: 'Created at',
                value: (u, i) => new Date(u.createdAt).toGMTString(),
              },
              {
                name: 'ID',
                value: (u, i) => <Link to={'/users/' + u._id}>{u._id}</Link>,
              },
            ]}
          />
        </React.Fragment>
      ) : (
        <p>Loading</p>
      )}
    </div>
  )
}

import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../AuthContext'
import TopNav from '../Nav'

const ProtectedRoute = () => {
  const auth = useAuth()
  let user = auth.user
  if (!user || user.role !== 'admin') {
    window.location.href = process.env.REACT_APP_MAINSITE_URL + '/login'
    return null
  } else {
    return (
      <>
        <TopNav />
        <Outlet />
      </>
    )
  }
}

export { ProtectedRoute }

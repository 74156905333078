import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { ProtectedRoute } from '../molecules/ProtectedRoute'
import { AuthProvider } from '../molecules/AuthContext'
import ChartsPage from '../pages/charts/'
import UsersPage from '../pages/users/'
import UserPage from '../pages/user/'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

const theme = createTheme()

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/users/:userid" element={<ProtectedRoute />}>
              <Route exact path="/users/:userid" element={<UserPage />} />
            </Route>
            <Route exact path="/users" element={<ProtectedRoute />}>
              <Route exact path="/users" element={<UsersPage />} />
            </Route>
            <Route exact path="/charts" element={<ProtectedRoute />}>
              <Route exact path="/charts" element={<ChartsPage />} />
            </Route>
            <Route path="/" element={<SetToken />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  )
}
function SetToken() {
  const location = useLocation()
  let q = new URLSearchParams(location.search)
  let token = q.get('token')
  if (token) {
    localStorage.setItem('token', token)
    return <Navigate to="/users" />
  } else {
    return (
      <>
        <p>Page not found</p>
      </>
    )
  }
}

import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import ArrayTable from '../../molecules/ArrayTable'
import { Button, TextField } from '@material-ui/core'
import { AuthContext } from '../../molecules/AuthContext'

const SharedChartsTab = () => {
  const [q, setQ] = useState('')
  const [charts, setCharts] = useState()
  const auth = useContext(AuthContext)

  const [pagination, setPagination] = useState()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(15)

  function handleChangePage(newPage) {
    setPage(newPage)
    setCharts(undefined)
  }

  function handleChangePerPage(event) {
    setPerPage(parseInt(event.target.value, 10))
    setPage(1)
    setCharts(undefined)
  }

  function handleChangeSearch(event) {
    setQ(event.target.value)
  }

  function submitSearch(e) {
    e.preventDefault()
    setCharts(undefined)
  }

  useEffect(() => {
    const loadCharts = async () => {
      let resp = await auth.fetch(
        `/charts?perPage=${perPage}&showcase=true&page=${page}&q=${encodeURIComponent(
          q
        )}`,
        {
          method: 'get',
        }
      )
      setPagination(resp.pagination)
      setCharts(resp.charts)
    }
    if (!charts) {
      loadCharts()
    }
  }, [charts, auth, page, perPage, q])

  return (
    <>
      <h1>Shared charts</h1>

      <React.Fragment>
        <form noValidate autoComplete="off" onSubmit={submitSearch}>
          <TextField
            fullWidth
            label="Chart name search"
            value={q}
            onChange={handleChangeSearch}
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
        </form>
        {charts ? (
          <ArrayTable
            pagination={pagination}
            items={charts}
            {...{ perPage, page, handleChangePage, handleChangePerPage }}
            fields={[
              {
                name: 'Title',
                value: (c, i) => c.title,
              },
              {
                name: 'Thumb',
                value: (c, i) => (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_MAINSITE_URL}/charts/${c._id}`}
                  >
                    {c.images && c.images.thumbnail ? (
                      <img src={c.images.thumbnail} alt="" />
                    ) : (
                      c._id
                    )}
                  </a>
                ),
              },
              {
                name: 'Created at',
                value: (c, i) => new Date(c.createdAt).toGMTString(),
              },
              {
                name: 'Owner',
                value: (c, i) => (
                  <Link to={`/users/${c.owner._id}`}>{c.owner.name}</Link>
                ),
              },
            ]}
          />
        ) : (
          <p>Loading</p>
        )}
      </React.Fragment>
    </>
  )
}

export default SharedChartsTab

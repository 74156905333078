import React from 'react'

import TablePaginationActions from '../../molecules/TablePaginationActions'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
}))

export default function ArrayTable({
  items,
  pagination,
  perPage,
  page,
  perPageOptions = [15, 50, 100],
  handleChangePage,
  handleChangePerPage,
  fields = [],
}) {
  const classes = useStyles()
  return (
    <Table className={classes.table} size="small" stickyHeader>
      <TableHead>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={perPageOptions}
            count={pagination.totalCount}
            rowsPerPage={perPage}
            page={page - 1}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={(e, newPage) => handleChangePage(newPage + 1)}
            onRowsPerPageChange={handleChangePerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
        <TableRow>
          <TableCell>Row</TableCell>
          {fields.map((f, i) => (
            <TableCell key={i}>{f.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, i) => (
          <TableRow key={item._id}>
            <TableCell>{i + 1 + (page - 1) * perPage}</TableCell>
            {fields.map((f, j) => (
              <TableCell key={j}>{f.value(item, i)}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={perPageOptions}
            count={pagination.totalCount}
            rowsPerPage={perPage}
            page={page - 1}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={(e, newPage) => handleChangePage(newPage + 1)}
            onRowsPerPageChange={handleChangePerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

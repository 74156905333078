import React, { useState } from 'react'
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
} from 'reactstrap'

const TopNav = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen((open) => !open)

  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand href="/">Admin</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink href="/users">Users</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/charts">Charts</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default TopNav

import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../molecules/AuthContext'
import ArrayTable from '../../molecules/ArrayTable'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Paper } from '@material-ui/core'
import { useParams } from 'react-router-dom'

export default function UserPage() {
  let { userid } = useParams()
  const auth = useContext(AuthContext)

  const [user, setUser] = useState()
  const [charts, setCharts] = useState()

  const [pagination, setPagination] = useState()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(12)

  function handleChangePage(newPage) {
    setPage(newPage)
    setCharts(undefined)
  }

  function handleChangePerPage(event) {
    setPerPage(parseInt(event.target.value, 10))
    setPage(1)
    setCharts(undefined)
  }

  async function changeOwner(chartId) {
    const newOwnerId = prompt('Enter ID of new owner')
    if (newOwnerId) {
      try {
        let newOwner = await auth.fetch('/users/' + newOwnerId, {
          method: 'get',
        })
        if (newOwner) {
          if (
            window.confirm(
              `Confirm new owner is: \nName: ${newOwner.name}\nProvider: ${
                newOwner.provider
              }\nCreated: ${new Date(newOwner.createdAt).toGMTString()}`
            )
          ) {
            try {
              let updatedChart = await auth.fetch(
                `/charts/${chartId}/transfer`,
                {
                  method: 'post',
                  body: JSON.stringify({ newOwnerId }),
                }
              )
              console.log(updatedChart)
              alert('Updated chart owner')
            } catch (e) {
              console.log(e)
              alert('Error transferring chart. check console')
            }
          }
        } else {
          alert('No user found')
        }
      } catch (e) {
        console.log(e)
        alert('Error finding owner with provided id. check console')
      }
    }
  }

  async function confirmDelete(e) {
    e.preventDefault()
    const name = prompt('Type the user name to confirm deletion')
    if (name === user.name) {
      try {
        let user = await auth.fetch('/users/' + userid, {
          method: 'delete',
        })
        console.log('deleted user', user)
        alert(`Sucessfully deleted ${user.name} ${user.id}`)
      } catch (e) {
        console.log(e)
        alert('There was an error deleting the user', e.message)
      }
    } else {
      alert("Name doesn't match. Not deleting")
    }
  }

  useEffect(() => {
    async function loadUser() {
      let resp = await auth.fetch('/users/' + userid, {
        method: 'get',
      })
      if (resp.ravelry && resp.ravelry._raw) {
        resp.ravelry = Object.assign(
          {},
          resp.ravelry,
          JSON.parse(resp.ravelry._raw)
        )
      }
      setUser(resp)
    }
    if (!user && userid) {
      loadUser()
    }
  }, [user, auth, userid])

  useEffect(() => {
    async function loadCharts() {
      let resp = await auth.fetch(
        `/charts?page=${page}&perPage=${perPage}&owner=${userid}&admin=true`,
        {
          method: 'get',
        }
      )
      console.log(resp)
      setCharts(resp.charts)
      setPagination(resp.pagination)
    }
    if (!charts && user) {
      loadCharts()
    }
  }, [charts, user, auth, userid, page, perPage])

  return (
    <React.Fragment>
      {user ? (
        <Paper>
          {user.isPro ? (
            <Badge badgeContent="pro" color="primary">
              <Typography variant="h2" component="h2">
                {user.name}
              </Typography>
            </Badge>
          ) : (
            <Typography variant="h2" component="h2">
              {user.name}
            </Typography>
          )}
          <h2>Created at {new Date(user.createdAt).toGMTString()}</h2>

          <p>
            {pagination && pagination.totalCount} charts |{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_MAINSITE_URL}/profile/${user._id}`}
            >
              Profile
            </a>
            {user.email && (
              <React.Fragment>
                {' '}
                |{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://app.intercom.io/apps/${process.env.REACT_APP_INTERCOM_APP_ID}/users/show?email=${user.email}`}
                >
                  Intercom
                </a>
              </React.Fragment>
            )}
            {user.isPro && user.stripe.customerId && (
              <React.Fragment>
                {' '}
                |{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://dashboard.stripe.com/customers/${user.stripe.customerId}`}
                >
                  Stripe
                </a>
              </React.Fragment>
            )}
            {user.provider === 'ravelry' && (
              <React.Fragment>
                {' '}
                |{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://www.ravelry.com/people/${
                    user.ravelry && user.ravelry.username
                      ? user.ravelry.username
                      : user.name
                  }`}
                >
                  Ravelry
                </a>
              </React.Fragment>
            )}
            {charts && pagination && charts.length === 0 && (
              <React.Fragment>
                {' '}
                |
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={confirmDelete}
                >
                  Delete user
                </Button>
              </React.Fragment>
            )}
          </p>
          <pre>{JSON.stringify(user, null, 2)}</pre>
          {charts && pagination && (
            <ArrayTable
              pagination={pagination}
              items={charts}
              perPageOptions={[12, 50, 100]}
              {...{ perPage, page, handleChangePage, handleChangePerPage }}
              fields={[
                {
                  name: 'Title',
                  value: (c, i) => c.title,
                },
                {
                  name: 'Created at',
                  value: (c, i) => new Date(c.createdAt).toGMTString(),
                },
                {
                  name: 'Link',
                  value: (c, i) => (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`${process.env.REACT_APP_MAINSITE_URL}/charts/${c._id}/edit`}
                    >
                      {c.images && c.images.thumbnail ? (
                        <img src={c.images.thumbnail} alt="" />
                      ) : (
                        c._id
                      )}
                    </a>
                  ),
                },
                {
                  name: 'Change owner',
                  value: (c, i) => (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => changeOwner(c._id)}
                    >
                      Change owner
                    </Button>
                  ),
                },
              ]}
            />
          )}
        </Paper>
      ) : (
        <p>Loading</p>
      )}
    </React.Fragment>
  )
}
